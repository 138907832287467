<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-title color="white">Dettaglio cliente</ion-title>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <div class="cliente_v2">
                    <div class="customer">
                        {{ riferimentoCliente(cliente) }}
                    </div>
                    <div v-if="cliente.customers_city" class="city">
                        {{ cliente.customers_city ? cliente.customers_city : "-" }}
                    </div>
                    <div class="field">
                        <div class="title">Indirizzo</div>
                        <div class="value">
                            {{ cliente.customers_address ? cliente.customers_address : "-" }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Cellulare</div>
                        <div class="value">
                            <span v-if="cliente.customers_mobile">
                                <a :href="`tel:${cliente.customers_mobile}`" class="mobile_link">{{ cliente.customers_mobile }}</a>
                            </span>
                            <span v-else>-</span>
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Telefono</div>
                        <div class="value">
                            <span v-if="cliente.customers_phone">
                                <a :href="`tel:${cliente.customers_phone}`" class="mobile_link">{{ cliente.customers_phone }}</a>
                            </span>
                            <span v-else>-</span>
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Email</div>
                        <div class="value">
                            <span v-if="cliente.customers_email">
                                <a :href="`mailto:${cliente.customers_email}`" class="mobile_link">{{ setShortEmail(cliente.customers_email) }}</a>
                            </span>
                            <span v-else>-</span>
                        </div>
                    </div>
                    <div class="field_note">
                        <div class="title">Descrizione</div>
                        <div class="value">
                            {{ cliente.customers_description ? cliente.customers_description : "-" }}
                        </div>
                    </div>
                </div>
                <!--         <div class="cliente">
          <div class="field">
            <div class="title">
              {{ riferimentoCliente(cliente) }}
            </div>
          </div>
          <div class="field">
            <div class="title">Città</div>
            <div class="value">
              {{ cliente.customers_city ? cliente.customers_city : "-" }}
            </div>
          </div>
          <div class="field">
            <div class="title">Indirizzo</div>
            <div class="value">
              {{ cliente.customers_address ? cliente.customers_address : "-" }}
            </div>
          </div>
          <div class="field">
            <div class="title">Cellulare</div>
            <div class="value">
              <span v-if="cliente.customers_mobile">
                <a :href="`tel:${cliente.customers_mobile}`" class="mobile_link">{{ cliente.customers_mobile }}</a>
              </span>
              <span v-else>-</span>
            </div>
          </div>
          <div class="field">
            <div class="title">Telefono</div>
            <div class="value">
              <span v-if="cliente.customers_phone">
                <a :href="`tel:${cliente.customers_phone}`" class="mobile_link">{{ cliente.customers_phone }}</a>
              </span>
              <span v-else>-</span>
            </div>
          </div>
          <div class="field">
            <div class="title">Email</div>
            <div class="value">
              <span v-if="cliente.customers_email">
                <a :href="`mailto:${cliente.customers_email}`" class="mobile_link">{{ setShortEmail(cliente.customers_email) }}</a>
              </span>
              <span v-else>-</span>
            </div>
          </div>
          <div class="field_note">
            <div class="title">Descrizione</div>
            <div class="value">
              {{ cliente.customers_description ? cliente.customers_description : "-" }}
            </div>
          </div>
        </div> -->

                <!-- Switcher -->
                <div class="data_switcher">
                    <div class="type" :class="{ active: showProgetti }" @click="setActiveData('progetti')">Progetti</div>
                    <div class="type" :class="{ active: showFatture }" @click="setActiveData('fatture')">Fatture</div>
                </div>

                <!-- Progetti -->
                <div class="container_section" v-if="showProgetti">
                    <!--           <div class="section_title">Progetti</div> -->
                    <div v-if="loading" class="loading_spinner">
                        <ion-spinner name="crescent" color="dark"></ion-spinner>
                    </div>
                    <div v-else>
                        <div v-if="progetti.length == 0" class="no_progetti_container">
                            <div class="no_progetti">Nessun progetto da visualizzare</div>
                        </div>
                        <div v-else class="container_progetti">
                            <div v-for="progetto in progetti" :key="progetto.projects_id" class="field" @click="openProject(progetto)">
                                <div class="title">
                                    {{ progetto.projects_name }}
                                </div>
                                <div class="value">
                                    <div class="stato_progetto">
                                        <span class="badge" :class="statoProgetto(progetto.projects_status)">
                                            {{ progetto.projects_status_value }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fatture -->
                <div class="container_section" v-if="showFatture">
                    <!--           <div class="section_title">Fatture</div> -->
                    <div v-if="loadingFatture" class="loading_spinner">
                        <ion-spinner name="crescent" color="dark"></ion-spinner>
                    </div>
                    <div v-else>
                        <div v-if="fatture.length == 0" class="no_fatture_container">
                            <div class="no_fatture">Nessuna fattura da visualizzare</div>
                        </div>
                        <div v-else class="container_fatture">
                            <div v-for="fattura in fatture" :key="fattura.documenti_contabilita_id" class="field">
                                <div class="info">
                                    <div class="title">
                                        {{ dateFormat(fattura.documenti_contabilita_data_emissione) }} - #{{ fattura.documenti_contabilita_numero }}/{{
                                            fattura.documenti_contabilita_serie
                                        }}
                                        {{ fattura.projects_name }}
                                    </div>
                                    <div class="importo">€ {{ fattura.documenti_contabilita_totale }}</div>
                                </div>
                                <div class="value">
                                    <div class="stato_fattura">
                                        <span class="badge" :class="statoFattura(fattura.documenti_contabilita_stato_pagamenti)">
                                            {{ fattura.documenti_contabilita_stato_pagamenti_value }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    //IonBackButton,
    IonTitle,
    IonContent,
    IonIcon,
    IonButton,
    IonSpinner,
    modalController,
} from "@ionic/vue";
import { defineComponent, onMounted, ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { arrowBackOutline } from "ionicons/icons";

import moment from "moment";

import { checkField, dateFormat, dateFormatWithMinutes } from "@/services/utils";
import { openToast } from "@/services/toast";

import apiProgetti from "@/services/progetti";
import apiContabilita from "@/services/contabilita";

import ProjectDetail from "@/components/project/ProjectDetail";

export default defineComponent({
    name: "ClienteDetail",
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    components: {
        IonPage,
        IonHeader,
        IonToolbar,
        IonButtons,
        //IonBackButton,
        IonTitle,
        IonContent,
        IonIcon,
        IonButton,
        IonSpinner,
    },
    setup(props, context) {
        const route = useRoute();
        const id = route.params.id; //richiesta_id by route params
        const router = useRouter();
        const loading = ref(false);
        const cliente = ref([]);
        //console.log(props.data);
        cliente.value = { ...props.data };
        //console.log(cliente.value);

        /**
         * Handle close modal by clicking on Chiudi button
         */
        const closeModal = () => {
            modalController.dismiss();
        };

        const showProgetti = ref(true);
        const showFatture = ref(false);
        /**
         * ! Switcher to show / hide interventi or ticket
         */
        function setActiveData(type) {
            if (type === "progetti") {
                showProgetti.value = true;
                showFatture.value = false;
            } else {
                showFatture.value = true;
                showProgetti.value = false;
            }
        }

        /**
         * Print correct customer data
         */
        const riferimentoCliente = (cliente) => {
            if (cliente.customers_company) {
                return cliente.customers_company;
            } else if (cliente.customers_name && !cliente.customers_last_name) {
                return `${cliente.customers_name}`;
            }
            return `${cliente.customers_name} ${cliente.customers_last_name}`;
        };

        /**
         * ! Limit lead email to 35 charactes
         */
        function setShortEmail(email) {
            if (!email) {
                return "-";
            } else {
                const trimmedString = email.length > 30 ? email.substring(0, 27) + "..." : email;
                return trimmedString;
            }
        }

        /**
         * Badge stato progetto
         */
        const statoProgetto = computed(() => {
            return (statusId) => {
                let className = "";
                if (statusId == 1) {
                    //da schedulare
                    className = "badge_open";
                } else if (statusId == 2) {
                    //in corso
                    className = "badge_in_progress";
                } else if (statusId == 3) {
                    //in pausa
                    className = "badge_pause";
                } else if (statusId == 4) {
                    //cancellato
                    className = "badge_canceled";
                } else if (statusId == 5) {
                    //completato
                    className = "badge_completed";
                }
                return className;
            };
        });

        /**
         * Open project detail page
         */
        async function openProject(project) {
            //console.log(richiestaDetail);
            const modal = await modalController.create({
                component: ProjectDetail,
                componentProps: {
                    data: project,
                },
            });
            return modal.present();
        }

        /**
         * Load customer projects
         */
        const progetti = ref([]);
        async function loadProgetti() {
            loading.value = true;
            try {
                const res = await apiProgetti.getClienteCommesse(cliente.value.customers_id);
                if (res.status === 0) {
                    progetti.value = res.data;
                } else {
                    openToast("Errore durante la richiesta dei progetti", "toast_danger");
                }
            } catch (error) {
                progetti.value = [];
                openToast("Errore durante la richiesta dei progetti", "toast_danger");
            } finally {
                loading.value = false;
            }
        }

        /**
         * Load customer fattura
         */
        const fatture = ref([]);
        const loadingFatture = ref(false);
        async function loadFatture() {
            loadingFatture.value = true;
            try {
                const res = await apiContabilita.getFatture(cliente.value.customers_id);
                if (res.status === 0) {
                    fatture.value = res.data;
                } else {
                    openToast("Errore durante la richiesta delle fatture", "toast_danger");
                }
            } catch (error) {
                fatture.value = [];
                openToast("Errore durante la richiesta delle fatture", "toast_danger");
            } finally {
                loadingFatture.value = false;
            }
        }

        /**
         * Badge stato fattura
         */
        const statoFattura = computed(() => {
            return (statusId) => {
                let className = "";
                if (statusId == 1) {
                    //non saldata
                    className = "badge_non_saldata";
                } else if (statusId == 2) {
                    //...
                    className = "badge_fattura";
                } else if (statusId == 3) {
                    //saldata
                    className = "badge_saldata";
                }
                return className;
            };
        });

        onMounted(() => {
            loadProgetti();
            loadFatture();
        });

        return {
            loading,
            statoProgetto,
            checkField,
            dateFormat,
            dateFormatWithMinutes,
            cliente,
            arrowBackOutline,
            closeModal,
            riferimentoCliente,
            progetti,
            openProject,
            fatture,
            loadingFatture,
            statoFattura,
            showProgetti,
            showFatture,
            setActiveData,
            setShortEmail,
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
.loading_spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.description_title {
    color: var(--ion-color-primary, #3880ff) !important;
    margin: 10px 0;
}
/* New rules */
.page_content {
    width: 100%;
    position: relative; /* to be used for new richiesta btn */
    padding: 16px;
    min-height: 100%;
    background: #f8fafc;
}
.cliente {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.cliente .field {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 6px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e2e8f0;
}
.cliente .field_note {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 12px;
}

.field .title,
.field_note .title {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
}
.field_note .title {
    margin-bottom: 8px;
}
.field .value,
.field_note .value {
    font-size: 14px;
}
.value .badge {
    font-size: 10px;
    font-weight: 600;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 4px;
}

.value .badge_success {
    background-color: rgb(220 252 231);
    color: rgb(34 197 94);
}
.value .badge_warning {
    background-color: #f97316;
    background-color: rgb(254 243 199);
    color: rgb(245 158 11);
}
.value .badge_danger {
    background-color: rgb(254 226 226);
    color: rgb(239 68 68);
}
ion-button {
    --color: #ffffff;
}

/** Progetti  */
.container_section {
    margin-top: 8px;
}
.container_section .section_title {
    font-size: 18px;
    font-weight: 500;
    color: #475569;
    margin-bottom: 8px;
}

.container_section .no_progetti_container,
.container_section .no_fatture_container {
    width: 100%;
}
.container_section .no_progetti_container .no_progetti,
.container_section .no_fatture_container .no_fatture {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    background-color: rgb(226 232 240);
    color: rgb(148 163 184);
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
}
/* .container_section .container_progetti {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 10px;
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 10px;
  box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
} */
.container_section .container_progetti,
.container_section .container_fatture {
    width: 100%;
}
.container_progetti .field,
.container_fatture .field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.container_fatture .field .info .importo {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
}
.container_progetti .field:last-child,
.container_fatture .field:last-child {
    margin-bottom: 0px;
}
.container_section .container_progetti .title,
.container_section .container_fatture .title {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
    margin-bottom: 4px;
}

.stato_progetto .badge {
    font-size: 10px;
    font-weight: 600;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 4px;
}

.stato_progetto .badge_open {
    background-color: rgb(207 250 254);
    color: rgb(6 182 212);
    font-weight: bold;
}
.stato_progetto .badge_in_progress,
.stato_fattura .badge_fattura {
    background-color: rgb(255 237 213);
    color: rgb(249 115 22);
    font-weight: bold;
}
.stato_progetto .badge_pause {
    background-color: rgb(237 233 254);
    color: rgb(139 92 246);
    font-weight: bold;
}
.stato_progetto .badge_canceled {
    color: rgb(243 244 246);
    background-color: rgb(31 41 55);
    font-weight: bold;
}
.stato_progetto .badge_completed,
.stato_fattura .badge_saldata {
    background-color: rgb(220 252 231);
    color: rgb(34 197 94);
    font-weight: bold;
}

.stato_fattura .badge_non_saldata {
    background-color: rgb(254 226 226);
    color: rgb(239 68 68);
    font-weight: bold;
}

/**
* Switcher Interventi e Ticket
*/
/** Switcher per tipologia documenti */
.data_switcher {
    width: 100%;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.data_switcher .type {
    width: 48%;
    color: #475569;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    padding: 8px 4px;
    border-radius: 20px;
    text-align: center;
}
.data_switcher .type.active {
    background-color: #086fa3;
    color: #ffffff;
}

.mobile_link {
    color: #086fa3;
    text-decoration: none;
}

/** */
/* lead v2*/
.cliente_v2 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.cliente_v2 .customer {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: #475569;
    margin-bottom: 8px;
}
.cliente_v2 .city {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
    margin-bottom: 8px;
}
.cliente_v2 .valore {
    margin-bottom: 16px;
}
.cliente_v2 .valore .badge_value {
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 4px;
    background-color: rgb(220 252 231);
    color: rgb(34 197 94);
}
.cliente_v2 .field {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 6px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e2e8f0;
}
.cliente_v2 .field:last-of-type {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 6px;
    border-bottom: none;
}
.cliente_v2 .field_note {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 12px;
}
</style>
